import { LoaderFunctionArgs } from '@remix-run/node';
import { Form, json, NavLink, useLoaderData, useNavigation } from '@remix-run/react';
import { authenticator, sessionStorage } from '~/auth/authenticator';
import { HorizontalDivider } from '~/components/Divider';
import googleLogo from '~/assets/google.svg';
import { toast } from 'sonner';
import { useEffect } from 'react';

export async function loader({ request }: LoaderFunctionArgs) {
	await authenticator.isAuthenticated(request, {
		successRedirect: '/dash',
	});
	const session = await sessionStorage.getSession(request.headers.get('Cookie'));
	const authError = session.get(authenticator.sessionErrorKey);

	// Commit session to clear any `flash` error message.
	return json(
		{ authError },
		{
			headers: {
				'set-cookie': await sessionStorage.commitSession(session),
			},
		},
	);
}

export default function LoginPage() {
	const { authError } = useLoaderData<typeof loader>();
	const navigation = useNavigation();

	useEffect(() => {
		if (authError) {
			toast.error(authError.message, {
				position: 'top-center',
			});
		}
	}, [authError]);

	return (
		<>
			<div className="flex flex-col justify-between gap-14 w-[400px]">
				<h1 className="text-2xl">Logo</h1>

				<div className="flex flex-col p-10 rounded-lg border border-gray-500 bg-gray-950 w-full">
					<h1 className="mb-10 text-xl">Login to Project Voice</h1>
					<Form method="post" className="flex flex-col gap-2" action="/auth/totp/generate_code">
						<label className="font-light">Email</label>
						<input className="font-light" type="email" name="email" placeholder="you@example.com" required />
						<button
							disabled={navigation.state !== 'idle'}
							className="mt-4 font-medium border border-gray-300 bg-gray-500 hover:bg-gray-400 transition-colors disabled:opacity-50 py-1.5 px-3 rounded-md"
							type="submit"
						>
							Login with Email
						</button>
					</Form>
					<div className="flex gap-4 items-center justify-center my-6">
						<HorizontalDivider className="bg-gray-500 !shrink-1" />
						<p className="text-gray-100">Or</p>
						<HorizontalDivider className="bg-gray-500 !shrink-1" />
					</div>
					<NavLink
						to="/auth/google/login"
						className="font-medium disabled:opacity-50 bg-gray-500 hover:bg-gray-400 transition-colors py-1.5 px-3 rounded-md flex items-center justify-center gap-3"
					>
						<img src={googleLogo} alt="Google Logo" className="size-[20px]" />
						<p>Login with Google</p>
					</NavLink>
					<div className="flex items-center justify-center mt-6 text-gray-200 text-sm text-center font-light">
						<p>
							Login methods are interchangeable. <br />
							You can use either one to login for a single email.
						</p>
					</div>
				</div>
			</div>
			{/* {authError && <pre>{JSON.stringify(authError, null, 2)}</pre>} */}
		</>
	);
}
